html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f7f7f7;
}

a {
  text-decoration: none;
}